<template>
    <Submenu
        v-if="config.community"
        :links="[{
            name: 'terminology.header',
            route: config.terminology.route,
            icon: 'flag',
            condition: config.terminology.enabled && (config.terminology.published || $isGranted('terminology')),
        }, {
            name: 'inclusive.header',
            route: config.inclusive.route,
            icon: 'book-heart',
            condition: config.inclusive.enabled,
        }, {
            name: 'calendar.header',
            route: config.calendar.route,
            icon: 'calendar-star',
            condition: config.calendar && config.calendar.enabled,
        }, {
            name: 'names.header',
            route: config.names.route,
            icon: 'signature',
            condition: config.names && config.names.enabled && (config.names.published || $isGranted('names')),
        }, {
            name: 'census.header',
            route: config.census.route,
            icon: 'user-chart',
            condition: config.census.enabled,
        }, {
            name: 'people.header',
            route: config.people.route,
            icon: 'user-friends',
            condition: config.people.enabled,
        }, {
            name: 'contact.team.nameShort',
            route: config.contact.team.route,
            icon: 'collective-logo.svg',
            iconInverse: darkMode,
        }, {
            name: 'workshops.header',
            route: config.workshops?.route,
            icon: 'chalkboard-teacher',
            condition: config.workshops?.enabled ?? false,
        }]"
    />
</template>

<script>
import { mapState } from 'pinia';
import useConfig from '../composables/useConfig.ts';
import { useMainStore } from '../store/index.ts';

export default {
    setup() {
        return {
            config: useConfig(),
        };
    },
    computed: {
        ...mapState(useMainStore, [
            'darkMode',
        ]),
    },
};
</script>
